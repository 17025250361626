import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { SHA256, enc } from 'crypto-js';
import { flatten, intersection, uniq } from 'lodash';

import { APIConstants } from '../../../../../api/src/services/Constants';
import { convertObjectToUrlParams } from '../../../common/Utils';
import { getChipsByCoverages } from '../../../utils/psxHeader.utils';
import { handleSearchBarSuggestionClickOrEnter } from '../../../utils/typeahead.utils';
import { getCoverageTypes } from '../../../utils/user.utils';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import {
  CareCategories,
  Constants,
  NULL_RESULTS,
  ReverseCoverageTypesCodes,
  TypeaheadBHType,
  TypeaheadProviderTypes,
} from '../../Constants';
import { ConstantsRoutes } from '../../ConstantsRoutes';
import { mappingSpecialityRollUpCodeToCategory } from '../../RecentActivity/helper';
import { getTypeAheadCategory } from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import {
  CustomAttributesBlock,
  getNameForLinkTrack,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';

const { GEO_LOCATION } = Constants.STORAGE_KEYS.SESSION;

export const getGeoLocationFromStorage = () =>
  storage.local.get(GEO_LOCATION) || {};

export const setGeoLocationToStorage = (value) => {
  storage.local.set(GEO_LOCATION, value);
  window.dispatchEvent(new Event('updateGeoLocationToStorage'));
};

export const getLinkNameForAnalytics = (value) =>
  getNameForLinkTrack(
    value?.suggestion ||
      value?.displayName ||
      `common search:${value?.psxKeyword}`,
    value?.providerType
  );

export const getSearchMethodForAnalytics = (value) => {
  let searchMethod;
  if (value?.psxKeyword) {
    searchMethod = 'guided';
  } else {
    searchMethod = 'predictive';
  }
  return searchMethod;
};

export const handleCloseLocationOptionsList = (
  isShowingLocationDrawer: boolean | null,
  isShowingLocationDropdown: boolean | null,
  callbackSetCursor: (a: number) => void,
  callbackHandleCloseLocationDrawer: () => void,
  callbackHandleCloseLocationDropdown: () => void
) => {
  if (isShowingLocationDrawer) {
    callbackHandleCloseLocationDrawer();
    callbackSetCursor(-1);
  }
  if (isShowingLocationDropdown) {
    callbackHandleCloseLocationDropdown();
    callbackSetCursor(-1);
  }
};

export const setTypeAheadResults = (
  suggestions,
  providers,
  facilities,
  network: string
) =>
  suggestions
    ?.map((item) => ({ ...item, showSearchIcon: true }))
    .concat(
      network && (providers?.length || facilities?.length)
        ? {
            section: Constants.TYPEAHEAD_SEARCH_HEADER,
            items: providers
              ?.concat(facilities)
              .map((item) => ({ ...item, showSearchIcon: false })),
          }
        : []
    );

export const setNullToken = (term, analyticsLinkName) =>
  tokenizer.create({
    originLinkNameForAnalytics: analyticsLinkName,
    search: term,
    searchType: '',
    searchMethod: 'typed',
    resultType: NULL_RESULTS,
  });

export const setStore = (
  {
    setDetailsStore,
    setTypeaheadSearchStore,
    setPCPSearchState,
    setAnalyticsState,
    setChipValue,
    setCoverageType,
  },
  value
) => {
  setDetailsStore({
    providerId: value?.providerId,
    providerName: value?.displayName,
    searchByProvider: value.searchByProvider,
  });
  setTypeaheadSearchStore({
    searchTerm: value?.searchTerm,
    searchMethod: value?.searchMethod,
    searchFromTypeAhead: value?.searchFromTypeAhead,
    typeaheadLinkName: value?.typeaheadLinkName,
    searchBySpeciality: value?.searchBySpeciality,
  });
  setPCPSearchState({
    choosePCP: value?.choosePCP,
    dependentSeqNbr: value?.dependentSeqNbr,
  });
  setAnalyticsState({
    linkName: value?.linkName,
  });
  setChipValue(
    mappingSpecialityRollUpCodeToCategory(
      value?.speciality,
      value?.providerType
    )
  );
  setCoverageType(value?.coverageType);
};

export const navigateToMixedResultsPage = (
  specialityPreferenceTable,
  value,
  {
    chipStore,
    setTypeaheadSearchStore,
    setPCPSearchState,
    setAnalyticsState,
    setCoverageType,
  },
  navigate
) => {
  const rollUpCodes =
    value?.pesKeyword ||
    value?.vertical?.code ||
    value?.includeSpecialityRollupCodes;

  const searchType =
    value?.searchType?.toLowerCase() || Constants.SEARCH_TYPES.SPECIALTY;

  const specialityPreferenceTuple = specialityPreferenceTable?.find((item) =>
    item.rollupCode
      ?.split(',')
      .every((val) => rollUpCodes?.split(',').includes(val))
  );

  setAnalyticsState({
    originLinkNameForAnalytics: value?.linkName,
    linkName: value?.linkName,
  });
  const chipStoreValues = {
    coverageType: value?.coverageType || ReverseCoverageTypesCodes.MEDICAL,
  };

  setCoverageType(chipStoreValues.coverageType);
  const pcpStoreValues = {
    choosePCP: value?.choosePCP,
    pcpIndicator: false,
  };
  setPCPSearchState(pcpStoreValues);
  const typeaheadStoreValues = {
    providerType: value?.providerType ?? '',
    search: value?.psxKeyword || value?.suggestion || value?.search,
    heading: value?.psxKeyword || value?.suggestion || value?.search,
    searchType,
    includeSpecialityRollupCodes: rollUpCodes,
    combinedIncludeSpecialityRollupCodes: rollUpCodes,
    pesKeyword: value?.pesKeyword,
    pseudoVerticals: value?.pseudoVerticals || '',
    preferredSpecialityCode: specialityPreferenceTuple
      ? specialityPreferenceTuple.specialityCode
      : '',
    searchBySpeciality: true,
  };

  const valueForTypeahead = {
    ...value,
    ...typeaheadStoreValues,
    keyword: value?.psxKeyword || value?.searchTerm || value?.userSearchTerm,
    category: value?.category,
    searchMethod: value?.searchMethod,
    searchTerm: value?.searchTerm,
    resultType:
      value?.resultType === NULL_RESULTS
        ? NULL_RESULTS
        : Constants.RESULT_SECTION.ALL,
    coverageTypes: uniq(value?.coverageTypes)?.join(','),
  };
  setTypeaheadSearchStore(valueForTypeahead);
  const urlParams = convertObjectToUrlParams(chipStore, {
    ...chipStoreValues,
    ...typeaheadStoreValues,
    ...valueForTypeahead,
  });
  if (navigate) {
    // naviate is provides for direct user search flow.
    navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
    return null;
  } else {
    // navige is not provided for broswer back button flow where
    // navigateion is not required and it returns the store values.
    return valueForTypeahead;
  }
};

export const getReqCoverageType = (
  coverageType: ReverseCoverageTypesCodes[] | ReverseCoverageTypesCodes,
  getCoverageType: Array<string>
) => {
  if (Array.isArray(coverageType)) {
    const filteredCoverages = coverageType.filter(
      (coverage) =>
        !(
          coverage === ReverseCoverageTypesCodes.MEDICAL &&
          !getCoverageType?.includes(ReverseCoverageTypesCodes.MEDICAL)
        )
    );
    if (filteredCoverages.includes(ReverseCoverageTypesCodes.MEDICAL))
      return ReverseCoverageTypesCodes.MEDICAL;
    return filteredCoverages[0];
  }
  return coverageType;
};

export const getReqCoverageTypes = (
  coverageType: ReverseCoverageTypesCodes[] | ReverseCoverageTypesCodes,
  getCoverageType: Array<string>
) => {
  if (Array.isArray(coverageType)) {
    return coverageType.filter(
      (coverage) =>
        !(
          coverage === ReverseCoverageTypesCodes.MEDICAL &&
          !getCoverageType?.includes(ReverseCoverageTypesCodes.MEDICAL)
        )
    );
  }
  return coverageType;
};

export const handleSearchSelected = (
  enableRetriveTypeAheadData,
  specialityPreferenceTable,
  value,
  store,
  navigate
) => {
  const updateValue = {
    ...value,
    choosePCP: false,
    dependentSeqNbr: value?.dependentSeqNbr,
    pseudoVerticals: value?.pseudoVerticals,
    isMixedSuggestions: value?.isMixedSuggestions || false,
    isUserTextSearch: value?.isUserTextSearch || false,
    searchFromTypeAhead: true,
  };

  if (value?.providerType === TypeaheadProviderTypes.PRACTITIONER) {
    const updateProviderValue = {
      ...value,
      choosePCP: value?.choosePCP,
      searchByProvider: true,
      searchFromTypeAhead: true,
      coverageType: value?.coverageType,
      dependentSeqNbr: value?.dependentSeqNbr,
      searchBySpeciality: false,
    };
    setStore(store, updateProviderValue);
    const urlParams = convertObjectToUrlParams(store.chipStore, {
      ...updateProviderValue,
    });
    navigate(`${ConstantsRoutes.PROVIDER_DETAILS.path}${urlParams}`);
    // we don't need to return anything as we are navigating to different page.
    return null;
  } else if (
    value?.providerType === TypeaheadProviderTypes.FACILITY ||
    value?.providerType === TypeaheadProviderTypes.MEDICAL_HEALTH_SUPPLIES ||
    value?.providerType === TypeaheadProviderTypes.VISION ||
    value?.providerType === TypeaheadProviderTypes.DENTAL ||
    (value?.providerType === TypeaheadProviderTypes.BEHAVIORAL_HEALTH &&
      value?.providerId?.startsWith(TypeaheadBHType.FAC))
  ) {
    setStore(store, {
      ...updateValue,
      searchBySpeciality: false,
    });
    const urlParams = convertObjectToUrlParams(store.chipStore, {
      ...updateValue,
    });
    navigate(`${ConstantsRoutes.FACILITY_DETAILS.path}${urlParams}`);
    // we don't need to return anything as we are navigating to different page.
    return null;
  } else if (
    value?.providerType === TypeaheadProviderTypes.PROVIDER_GROUP ||
    (value?.providerType === TypeaheadProviderTypes.BEHAVIORAL_HEALTH &&
      value?.providerId?.startsWith(TypeaheadBHType.GRP))
  ) {
    setStore(store, {
      ...updateValue,
      searchBySpeciality: false,
    });
    const urlParams = convertObjectToUrlParams(store.chipStore, {
      ...updateValue,
    });
    navigate(`${ConstantsRoutes.PROVIDER_GROUP_DETAILS.path}${urlParams}`);
    // we don't need to return anything as we are navigating to different page.
    return null;
  } else {
    // exisiting flow for mixed results page.
    if (!enableRetriveTypeAheadData) {
      navigateToMixedResultsPage(
        specialityPreferenceTable,
        updateValue,
        store,
        navigate
      );
      // we don't need to return anything as we are navigating to different page.
      return null;
      // enableRetriveTypeAheadData is turned on, navigate is provided for direct user search flow.
      // We need to pass only the independent variables to the url and navite to the search results page.
    } else if (navigate) {
      const urlParams = convertObjectToUrlParams(store.chipStore, {
        search: value?.psxKeyword || value?.suggestion || value?.search,
        searchType: value?.searchType,
        linkName: value?.linkName,
      });
      navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
      // we don't need to return anything as we are navigating to different page.
      return null;
    } else {
      // navigate is not provided for broswer back button flow where
      // navigateion is not required and it returns the store values.
      const typeaheadValues = navigateToMixedResultsPage(
        specialityPreferenceTable,
        updateValue,
        store,
        navigate
      );
      return typeaheadValues;
    }
  }
};

export const handleRandomSearch = (navigate, searchTerm, analyticsLinkName) => {
  navigate(
    `${ConstantsRoutes.NULL_SEARCH_RESULTS.path}/${setNullToken(
      searchTerm,
      analyticsLinkName
    )}`
  );
};

export const mapLocationType = (type) => {
  switch (type) {
    case APIConstants.PROVIDER_TYPES.providerGroup:
      return 'medical group';
    case APIConstants.PROVIDER_TYPES.practitioner:
      return 'provider';
    default:
      return 'facilities';
  }
};

export const getProviderResultTrackingData = (result) => {
  const { providerId, providerType, locationId, coverageType } = result;

  const mappedType = mapLocationType(providerType);

  if (locationId.endsWith('NoTin-T-NoAddress')) {
    return {
      providerType: mappedType,
      providerId,
    };
  }

  const locationSplit = locationId.split('-');
  const hashedTin = SHA256(locationSplit[1]).toString(enc.Hex);
  return {
    providerId,
    providerType: mappedType,
    tin: hashedTin,
    locationId: locationSplit[3],
    coverageType: coverageType?.at(0),
  };
};

export const getTypeAheadResultTrackingData = (TypeAheadData) => {
  const suggestions = TypeAheadData.lang_provider.langProvider || [];

  const providers: any = [];
  TypeAheadData.organizations_uhc.orgData.map((org) =>
    providers.push(getProviderResultTrackingData(org))
  );
  TypeAheadData.practitioners_uhc.provData.map((prac) =>
    providers.push(getProviderResultTrackingData(prac))
  );

  return {
    suggestions,
    providers,
  };
};

export const handleKeywordSuggestionKeyDownHelper = (
  ev: {
    key: string;
    preventDefault: () => void;
    target: { value: string };
  },
  specialityPreferenceTable,
  enableTypeAheadWildCardSearch,
  enableRetriveTypeAheadData,
  {
    keywordSearchTerm,
    commonSearchesData,
    isKeywordSearchLoading,
    searchButtonResults,
    setEnter,
    cursor,
    results,
    combineRollUpCodes,
    aggregatedAoeCodes,
    store,
    navigate,
    getTypeaheadData,
    headers,
    setCursor,
    convertTypeaheadProviderIdAndType,
    setIsFocusedOnKeywordSearchInput,
    searchInputOptionLocation,
    blurSearchInput,
    choosePCP,
    dependentSeqNbr,
    getCoverageType,
    bhProgramFacilityAgg,
    enableUESSuggestionMatch,
    currentMember,
  }
) => {
  const minCursor = -1;
  const maxCursor =
    keywordSearchTerm?.trim().length < 2
      ? commonSearchesData.length + 1
      : searchButtonResults.length + 1;
  let value: any;

  if (['ArrowUp', 'ArrowDown'].some((key) => key === ev.key)) {
    ev.preventDefault();
  }
  const allowEnter = enableTypeAheadWildCardSearch || cursor !== minCursor;
  if (ev.key === 'Enter' && allowEnter && !isKeywordSearchLoading) {
    const analyticsLinkName = 'search enter';
    const typeaheadLinkName = 'typeahead search';
    const userSearchTerm = ev.target.value;
    setEnter(true);
    let customAttributesBlock;
    if (keywordSearchTerm?.length > 1) {
      if (cursor === -1) {
        handleSearchBarSearchButtonClickOrEnter(
          {
            enableRetriveTypeAheadData,
            enableUESSuggestionMatch,
            typeAheadSuggestions: results || [],
            navigate,
            headers,
            analyticsLinkName,
            typeaheadLinkName,
            currentMember,
            userSearchTerm,
            combinedRollupCodes: combineRollUpCodes,
            aggregatedAoeCodes,
            bhProgramFacilityAgg,
            setIsFocusedOnKeywordSearchInput,
            convertTypeaheadProviderIdAndType,
            getTypeAheadCategory,
            store,
          },
          specialityPreferenceTable
        );
      } else {
        value = getTypeaheadData();
        customAttributesBlock = value
          ? ({
              correlationId: headers?.correlationId,
              ...convertTypeaheadProviderIdAndType(value),
            } as CustomAttributesBlock)
          : undefined;
        setIsFocusedOnKeywordSearchInput(false);
        handleSearchBarSuggestionClickOrEnter({
          specialityPreferenceTable,
          value,
          searchTerm: keywordSearchTerm,
          getCoverageType,
          dependentSeqNbr,
          choosePCP,
          ...store,
          navigate,
        });
      }
    }
    adobeLinkTrackEvent({
      name: analyticsLinkName,
      location: `body:${searchInputOptionLocation}`,
      type: 'internal',
      customAttributesBlock,
    });
    blurSearchInput();
  } else if (ev.key === 'ArrowUp' && cursor > minCursor) {
    setCursor((previous) => previous - 1);
  } else if (ev.key === 'ArrowDown' && cursor < maxCursor) {
    setCursor((previous) => previous + 1);
  }
};

export const isCommonSearch = (keywordSearchTerm) =>
  keywordSearchTerm?.trim().length < Constants.MIN_KEYWORD_SEARCHTERM_LENGTH;

export const handleSearchBarSearchButtonClickOrEnter = (
  {
    enableUESSuggestionMatch,
    enableRetriveTypeAheadData,
    typeAheadSuggestions,
    navigate,
    headers,
    analyticsLinkName,
    typeaheadLinkName,
    currentMember,
    userSearchTerm,
    combinedRollupCodes,
    aggregatedAoeCodes,
    bhProgramFacilityAgg,
    setIsFocusedOnKeywordSearchInput,
    convertTypeaheadProviderIdAndType,
    getTypeAheadCategory,
    store,
  },
  specialityPreferenceTable
) => {
  const isMixedSuggestions =
    typeAheadSuggestions.some((suggest) => suggest.suggestion) &&
    typeAheadSuggestions.some((suggest) => suggest.section);

  let isMatchingAutoSuggestion;
  if (enableUESSuggestionMatch) {
    isMatchingAutoSuggestion = typeAheadSuggestions.find((suggestion) =>
      suggestion.suggestion
        ? suggestion?.suggestion.toLowerCase() === userSearchTerm.toLowerCase()
        : false
    );
  }

  let searchType;
  let isUserTextSearch = false;

  if (typeAheadSuggestions.length > 0) {
    const isOnlyNamed = typeAheadSuggestions.every(
      (suggest) => suggest.section
    );
    const isOnlySpecialty = typeAheadSuggestions.every(
      (suggest) => suggest.suggestion
    );

    if (isOnlyNamed || isMixedSuggestions) {
      searchType = Constants.SEARCH_TYPES.NAME;
    }

    if (isOnlySpecialty) {
      searchType = Constants.SEARCH_TYPES.SPECIALTY;
    }
  } else {
    searchType = Constants.SEARCH_TYPES.SPECIALTY;
    isUserTextSearch = true;
  }
  const combinedCoverageTypes = flatten(
    typeAheadSuggestions
      .filter((suggest) => suggest.suggestion)
      .map((suggest) => [...suggest.coverageType])
  );

  const memberCoverages = getCoverageTypes(currentMember);
  const finalCoverageTypes = combinedCoverageTypes.length
    ? intersection(combinedCoverageTypes, memberCoverages)
    : memberCoverages;
  let customAttributesBlock;
  if (userSearchTerm?.length > 1) {
    customAttributesBlock = userSearchTerm
      ? ({
          correlationId: headers?.correlationId,
          ...convertTypeaheadProviderIdAndType(userSearchTerm),
        } as CustomAttributesBlock)
      : undefined;
    setIsFocusedOnKeywordSearchInput(false);
    const typeaheadValues = handleSearchSelected(
      enableRetriveTypeAheadData,
      specialityPreferenceTable,
      {
        userSearchTerm,
        linkName: analyticsLinkName,
        typeaheadLinkName,
        searchMethod: 'typed',
        resultType: userSearchTerm
          ? Constants.RESULT_SECTION.ALL
          : NULL_RESULTS,
        search: userSearchTerm,
        heading: userSearchTerm,
        isMixedSuggestions,
        searchType,
        includeSpecialityRollupCodes: isMatchingAutoSuggestion
          ? isMatchingAutoSuggestion.vertical
          : combinedRollupCodes,
        aoeCodes: isMatchingAutoSuggestion
          ? isMatchingAutoSuggestion.aoeCodes
          : aggregatedAoeCodes,
        coverageTypes: finalCoverageTypes,
        coverageType:
          memberCoverages.length === 0 || memberCoverages.includes('M')
            ? 'M'
            : memberCoverages?.[0],
        isUserTextSearch,
        bhProgramFacilityAgg: isMatchingAutoSuggestion
          ? isMatchingAutoSuggestion.bhProgramFacility
          : bhProgramFacilityAgg,
      },
      store,
      navigate
    );
    return typeaheadValues;
  }
  let analyticsLocationName = 'search-input-option-list';
  let analyticsSearchBlock;
  if (userSearchTerm) {
    analyticsLocationName += `:${getTypeAheadCategory(userSearchTerm)}`;
    analyticsSearchBlock = {
      linkPosition: '1',
    };
  }
  adobeLinkTrackEvent({
    name: analyticsLinkName,
    location: analyticsLocationName,
    type: 'internal',
    destinationUrl: '',
    ...(analyticsSearchBlock && { searchBlock: analyticsSearchBlock }),
    customAttributesBlock,
  });
  return null;
};

export const getFilteredChips = (
  currentMember,
  isMixedResultsPage,
  categoryCount,
  visionChipEnabled,
  dentalChipEnabled
) => {
  const chips = getChipsByCoverages(
    Constants.MOCK_CARE_CATEGORIES,
    currentMember?.eligibility
  );

  const enabledChips = chips.map((chip) => {
    const enabled = !isMixedResultsPage
      ? true
      : !!categoryCount.find(
          (item) => item.chipCategory === chip.id && item.chipEnabled
        );
    return { ...chip, enabled };
  });

  const filteredChips = enabledChips.filter((chip) => {
    if (chip.value === CareCategories.VISION && visionChipEnabled === false) {
      return false;
    }
    if (chip.value === CareCategories.DENTAL && dentalChipEnabled === false) {
      return false;
    }
    if (isMixedResultsPage && chip.value === CareCategories.ALL) {
      return false;
    }
    return true;
  });

  return filteredChips;
};

export const validateChipsToTabEnabled = (
  chipsToTabsEnabled,
  isMixedResultsPage
) => {
  let check;
  if (chipsToTabsEnabled && isMixedResultsPage) {
    check = true;
  }
  if (chipsToTabsEnabled && !isMixedResultsPage) {
    check = false;
  }
  if (!chipsToTabsEnabled && !isMixedResultsPage) {
    check = true;
  }
  if (!chipsToTabsEnabled && isMixedResultsPage) {
    check = true;
  }
  return check;
};
