import { Constants, NULL_RESULTS } from '../common/Constants';

import {
  getLinkNameForAnalytics,
  getReqCoverageType,
  getReqCoverageTypes,
  getSearchMethodForAnalytics,
  handleSearchSelected,
  isCommonSearch,
} from '../common/PSXHeader/SearchBar/utils';

export const handleSearchBarSuggestionClickOrEnter = (args: any) => {
  const {
    enableRetriveTypeAheadData,
    specialityPreferenceTable,
    value,
    searchTerm,
    getCoverageType,
    dependentSeqNbr,
    choosePCP,
    chipStore,
    setTypeaheadSearchStore,
    setAnalyticsState,
    setPCPSearchState,
    setChipValue,
    setCoverageType,
    setDetailsStore,
    navigate,
  } = args;
  const commonSearch = isCommonSearch(searchTerm);
  const typeaheadValues = handleSearchSelected(
    enableRetriveTypeAheadData,
    specialityPreferenceTable,
    {
      ...value,
      searchTerm,
      resultType: value ? Constants.RESULT_SECTION.ALL : NULL_RESULTS,
      linkName: getLinkNameForAnalytics(value),
      typeaheadLinkName: `${getLinkNameForAnalytics(value)}-${value?.vertical}`,
      searchMethod: getSearchMethodForAnalytics(value),
      fromViewAll: false,
      includeSpecialityRollupCodes:
        value?.vertical?.code || value?.vertical || value?.pesKeyword,
      searchType:
        value?.vertical ||
        value?.pseudoVerticals ||
        value?.aoeCodes?.length > 0 ||
        commonSearch ||
        value?.bhProgramFacility
          ? Constants.SEARCH_TYPES.SPECIALTY
          : Constants.SEARCH_TYPES.NAME,
      coverageType: getReqCoverageType(value?.coverageType, getCoverageType),
      coverageTypes: getReqCoverageTypes(value?.coverageType, getCoverageType),
      dependentSeqNbr,
      choosePCP,
      bhProgramFacilityAgg: value?.bhProgramFacility,
    },
    {
      chipStore,
      setTypeaheadSearchStore,
      setAnalyticsState,
      setPCPSearchState,
      setChipValue,
      setCoverageType,
      setDetailsStore,
    },
    navigate
  );
  return typeaheadValues;
};

export const excludeDisabledResults = (
  memberCoverageType,
  suggestionResults
) => {
  const filteredResults: Object[] = [];
  suggestionResults.forEach((result) => {
    if (memberCoverageType.some((type) => result.coverageType.includes(type))) {
      filteredResults.push(result);
    }
  });

  return filteredResults;
};
