/* eslint-disable no-nested-ternary */
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import uniqBy from 'lodash/uniqBy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useSearchStore } from '../../../../store/useSearchStore';
import { SearchState } from '../../../../store/useSearchStore/searchStore';
import { getOnlineRetailers } from '../../../../utils/user.utils';
import { TypeaheadBHType, TypeaheadProviderTypes } from '../../../Constants';
import { PSXIcon } from '../../../PSXIcon';
import { DisplayCoverageTypes } from './DisplayCoverageTypes';
import {
  Divider,
  DropdownContainer,
  ProviderIcon,
  SuggestionListContainer,
  isPrimaryActiveSuggestion,
} from './KeywordSearchDropdown.styled';
import { SuggestionItem } from './SuggestionItem';

type Props = {
  typeAheadSuggestions;
  onSuggestionClick;
  activeSuggestion;
  getCoverageType;
  keywordSearchTerm;
  memberDDP;
  sortItems;
};
export const SuggessionList = ({
  typeAheadSuggestions,
  onSuggestionClick,
  activeSuggestion,
  getCoverageType,
  keywordSearchTerm,
  memberDDP,
  sortItems,
}: Props) => {
  const { t } = useTranslation();

  const formatSpecialty = (specialtiesList = []) => {
    const distinctSpecialty = uniqBy(specialtiesList, 'category');
    const list = distinctSpecialty.slice(0, 2);
    let displaySpecialty = '';
    list.map((item: any, index: number) => {
      displaySpecialty += `${item.category}${
        index !== list.length - 1 ? ', ' : ''
      }`;
      return displaySpecialty;
    });
    if (distinctSpecialty.length > 2) {
      displaySpecialty += ` (${distinctSpecialty.length - 2} ${t('More')})`;
    }

    return displaySpecialty;
  };
  const { setSearchStore } = useSearchStore(
    useShallow((state: SearchState) => ({
      onlineRetailers: state.searchStore.onlineRetailers,
      setSearchStore: state.setSearchStore,
    }))
  );
  return (
    <React.Fragment>
      <Divider />
      <DropdownContainer
        data-testid="primary-dropdown-container"
        role="listbox"
      >
        <SuggestionListContainer
          data-auto-testid="suggestion-list-container"
          data-testid="suggestion-list-containter"
        >
          {typeAheadSuggestions?.length > 0 &&
            typeAheadSuggestions?.sort(sortItems).map((value, index) => {
              if (value?.suggestion) {
                return (
                  <SuggestionItem
                    activeSuggestion={activeSuggestion}
                    data-testid="search-input-box-typeahead-suggestion"
                    getCoverageType={getCoverageType}
                    index={index}
                    key={value.id}
                    keywordSearchTerm={keywordSearchTerm}
                    memberDDP={memberDDP}
                    onClick={() => {
                      onSuggestionClick(value, index);
                      setSearchStore({
                        onlineRetailers: getOnlineRetailers(
                          value.isOnlineProviders
                        ),
                      });
                    }}
                    value={value}
                  />
                );
              }
              if (value?.section) {
                return (
                  <React.Fragment>
                    {typeAheadSuggestions?.length > 1 ? <Divider /> : ''}
                    <Text
                      color="$gray8"
                      data-testid="heading-typeahead-suggested-searches"
                      fontWeight={700}
                      size="14.22px"
                    >
                      {value.section}
                    </Text>
                    {value?.items?.sort(sortItems).map((item, ix) => (
                      <li
                        aria-posinset={ix + typeAheadSuggestions.length - 1}
                        aria-selected={
                          activeSuggestion ===
                          ix + typeAheadSuggestions.length - 1
                        }
                        id={
                          activeSuggestion ===
                          ix + typeAheadSuggestions.length - 1
                            ? 'primary-search-bar-result-suggestion'
                            : ''
                        }
                        role="option"
                      >
                        <Link
                          color="$interactive1"
                          css={
                            item?.coverageType.some((coverageCode) =>
                              getCoverageType.includes(coverageCode)
                            )
                              ? {
                                  '&.abyss-link-root':
                                    activeSuggestion ===
                                    ix + typeAheadSuggestions.length - 1
                                      ? isPrimaryActiveSuggestion
                                      : '',
                                }
                              : { pointerEvents: 'none' }
                          }
                          data-testid="search-input-box-typeahead-suggestion-section"
                          fontWeight="bold"
                          index={ix}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            ev.preventDefault();
                            onSuggestionClick(item, ix);
                          }}
                          tabIndex={-1}
                          transform="capitalize"
                        >
                          <div style={{ display: 'flex' }}>
                            <div>
                              {item?.providerType ===
                                TypeaheadProviderTypes.PROVIDER_GROUP ||
                              (item?.providerType ===
                                TypeaheadProviderTypes.BEHAVIORAL_HEALTH &&
                                item?.providerId?.startsWith(
                                  TypeaheadBHType.GRP
                                )) ? (
                                <IconMaterial
                                  data-testid="providergroup-icon"
                                  icon="people"
                                  style={ProviderIcon}
                                />
                              ) : item?.providerType ===
                                TypeaheadProviderTypes.PRACTITIONER ? (
                                <IconMaterial
                                  data-testid="provider-icon"
                                  icon="person"
                                  style={ProviderIcon}
                                />
                              ) : item?.providerType ===
                                  TypeaheadProviderTypes.FACILITY ||
                                item?.providerType ===
                                  TypeaheadProviderTypes.MEDICAL_HEALTH_SUPPLIES ||
                                (item?.providerType ===
                                  TypeaheadProviderTypes.BEHAVIORAL_HEALTH &&
                                  item?.providerId?.startsWith(
                                    TypeaheadBHType.FAC
                                  )) ? (
                                <PSXIcon
                                  data-testid="facility-icon"
                                  icon="hospital"
                                  style={ProviderIcon}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                            <Text
                              color="$gray7"
                              fontWeight={500}
                              size="14.22px"
                            >
                              <Text
                                color={
                                  !item?.coverageType.some((coverageCode) =>
                                    getCoverageType.includes(coverageCode)
                                  )
                                    ? '$gray6'
                                    : '$info1'
                                }
                                fontWeight={700}
                              >
                                {t(item?.displayName)}
                              </Text>
                              <Text
                                style={{
                                  marginLeft: '8px',
                                  marginRight: '8px',
                                }}
                              >
                                {formatSpecialty(item.speciality)}
                              </Text>
                              <Text style={{ display: 'inline-block' }}>
                                <DisplayCoverageTypes
                                  getCoverageType={getCoverageType}
                                  item={item}
                                  memberDDP={memberDDP}
                                />
                              </Text>
                            </Text>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </React.Fragment>
                );
              }
              return null;
            })}
        </SuggestionListContainer>
      </DropdownContainer>
    </React.Fragment>
  );
};
