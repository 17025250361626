import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  Constants,
  MemberCoverageTypeToUICoverageTypeConversion,
} from '../../common/Constants';
import { FeatureFlags } from '../../common/ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import {
  handleSearchBarSearchButtonClickOrEnter,
  setTypeAheadResults,
} from '../../common/PSXHeader/SearchBar/utils';
import {
  convertTypeaheadProviderIdAndType,
  getTypeAheadCategory,
} from '../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { getIntValue } from '../../common/Utils/getIntValue';
import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../store/useDetailsStore';
import { usePCPStore } from '../../store/usePCPStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { returnSuppressFlag } from '../../utils/featureSuppress';
import { getSuppressFacilityFlags } from '../../utils/providerDetails.utils';
import {
  excludeDisabledResults,
  handleSearchBarSuggestionClickOrEnter,
} from '../../utils/typeahead.utils';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCoverageTypes,
  getCurrentMember,
  getDependentInfo,
  getDomainForAutoComplete,
  getMemberCoverage,
  getMemberShipCategory,
} from '../../utils/user.utils';
import { useAutoCompleteQuery } from '../useAutoCompleteQuery';
import { useFeatureFlag } from '../useFeatureFlag';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import { getExcludeNatAncForNetworkIds } from '../useProviderSearch/useProviderSearch';
import { useTypeaheadPseudoRollupTable } from '../useTypeaheadPseudoRollup';

enum UesSuggestions {
  EAP = 'EAP',
}

enum PseudoRollupCodeSearchDescription {
  FSF = 'Freestanding facility',
  PLN = 'preferred lab network (PLN)',
  OX_PLN = 'Oxford preferred lab network',
  DDP_RAD = 'Designated Diagnostic Provider: Imaging',
  DDP_LAB = 'Designated Diagnostic Provider: Lab',
}

const parseSearchParams = (searchParams: URLSearchParams) => {
  const params = Object.fromEntries(searchParams.entries());
  const { searchType = '', search = '', linkName } = params;

  return {
    searchType,
    search,
    linkName,
  };
};

interface Props {
  onTypeAheadDataLoaded: (variables: any) => void;
}

export const useTypeAheadData = ({ onTypeAheadDataLoaded }: Props) => {
  const [searchParams] = useSearchParams();

  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: any) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );
  const { setPCPSearchState, choosePCP, dependentSeqNbr } = usePCPStore(
    useShallow((state: any) => ({
      setPCPSearchState: state.setPCPSearchState,
      choosePCP: state.pcpSearchState.choosePCP,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: any) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { coverageType, setCoverageType, setChipValue } = chipStore;

  const { typeaheadSearchStore, setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: any) => ({
      typeaheadSearchStore: state.typeaheadSearchStore,
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );

  const currentMember = dependentSeqNbr
    ? getDependentInfo(dependentSeqNbr)
    : getCurrentMember();

  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const commonSearchesLagoon = useLagoon(
    Constants.LAGOON_TABLE.COMMON_SEARCHES
  )();

  const storedLocation = useGeoLocationStorage();
  const { longitude, latitude } = storedLocation;
  const network = getMemberCoverage(currentMember, featureFlags);
  const language = getLanguage()?.code || 'en';
  const typeaheadParams = useLagoon(Constants.LAGOON_TABLE.TYPEAHEAD_PARAMS)();
  const membershipCategory = getMemberShipCategory(currentMember, featureFlags);

  const limitSpl = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.LIMIT_SPECIALTIES,
    Constants.TYPEAHEAD_PARAMS.LIMIT_SPECIALTIES_DEFAULT
  );
  const limitOrg = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.LIMIT_ORG,
    Constants.TYPEAHEAD_PARAMS.LIMIT_ORG_DEFAULT
  );
  const limitPrn = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.LIMIT_PRACTITIONER,
    Constants.TYPEAHEAD_PARAMS.LIMIT_PRACTITIONER_DEFAULT
  );
  const radius = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.RADIUS,
    Constants.TYPEAHEAD_PARAMS.RADIUS_DEFAULT
  );

  const { claimSystemTypeCode, eligibilitySystemTypeCode } =
    getClaimOrEligibilitySystemTypeCode(currentMember, coverageType);
  const pseudoRollupCodeTable = useTypeaheadPseudoRollupTable();

  const {
    suppressPreferredFacilityPLN,
    suppressPreferredFacilityDDP,
    suppressPreferredFacilityFSF,
  } = getSuppressFacilityFlags();

  const suppressEAPKeyword = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.EAP
  );

  const pseudoRollupCodeMap = new Map(
    pseudoRollupCodeTable.map(({ dntDescription, pseudoRollupCode }) => [
      dntDescription,
      pseudoRollupCode,
    ])
  );

  const keywordSuppressionFlags = [
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.PLN
      ),
      value: suppressPreferredFacilityPLN,
    },
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.OX_PLN
      ),
      value: suppressPreferredFacilityPLN,
    },
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.FSF
      ),
      value: suppressPreferredFacilityFSF,
    },
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.DDP_LAB
      ),
      value: suppressPreferredFacilityDDP,
    },
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.DDP_RAD
      ),
      value: suppressPreferredFacilityDDP,
    },
    {
      suggestion: UesSuggestions.EAP,
      value: suppressEAPKeyword,
    },
  ];

  const [
    enableRetriveTypeAheadData,
    enableUESSuggestionMatch,
    hideDisabledTypeahead,
    useMedicalNewRollUpCodes,
    useBHNewRollUpCodes,
    useVisionNewRollUpCodes,
    useDentalNewRollUpCodes,
    useAutocompleteRollUpCodeNameSearch,
    useBHVirtualCarePseudoRollupCodeSearch,
    useOxfordPlnFlag,
    useEnableBhFacilitiesProgramSearchFlag,
    includeAoeOnlySuggestions,
  ] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_RETRIVE_TYPEAHEAD_DATA,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_UES_SUGGESTION_MATCH,
    FeatureFlags.FEATURE_FLAG_KEY.HIDE_DISABLED_TYPEAHEAD,
    ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES,
    ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES,
    ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES,
    ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES,
    ConstantsLagoon.FEATURE_FLAGS.USE_UES_ROLLUP_CODE_FOR_NAME_SEARCH,
    ConstantsLagoon.FEATURE_FLAGS.BEHAVIORAL_HEALTH_VIRTUAL_CARE,
    ConstantsLagoon.FEATURE_FLAGS.OXFORD_PLN,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_BH_FACILITIES_PROGRAM_SEARCH,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_BH_AOE_TYPEAHEAD,
  ]);

  const allConfigss = useLagoon('config')();
  const excludeNatAncForNetworkIds = allConfigss?.find(
    (row) => row.key === 'EXCLUDE_NAT_ANC_FOR_NETWORK_IDS'
  )?.value;

  const specialityPreferenceTable = useLagoon(
    Constants.LAGOON_TABLE.SPECIALITY_PREFERENCE
  )().map((item) => ({
    rollupCode: item.rollupCode,
    specialityCode: item.specialityCode,
  }));

  const memberCoverages = getCoverageTypes(currentMember);
  const convertedMemberCoverages = memberCoverages?.map(
    (cov) => MemberCoverageTypeToUICoverageTypeConversion[cov]
  );
  const dentalCoverage = convertedMemberCoverages?.find((cov) => cov === 'D');
  const visionCoverage = convertedMemberCoverages?.find((cov) => cov === 'V');
  const behavioralCoverage = convertedMemberCoverages?.find(
    (cov) => cov === 'B'
  );
  const medicalCoverage = convertedMemberCoverages?.find((cov) => cov === 'M');
  const getCoverageType = [
    dentalCoverage,
    visionCoverage,
    medicalCoverage,
    behavioralCoverage,
  ];

  const parsedSearchParams = parseSearchParams(searchParams);
  // Independent variables to uniquely identify the search
  const { search, searchType, linkName } = parsedSearchParams;

  const typeaheadRequestData = {
    query: encodeURIComponent(search.toString().trim()),
    network,
    latitude,
    longitude,
    lang: language,
    limitSpl,
    limitOrg,
    limitPrn,
    radius,
    membershipCategory,
    claimSystemTypeCode,
    eligibilitySystemTypeCode,
    pseudoRollupCodeTable,
    keywordSuppressionFlags,
    useMedicalNewRollUpCodes,
    useBHNewRollUpCodes,
    useVisionNewRollUpCodes,
    useDentalNewRollUpCodes,
    useAutocompleteRollUpCodeNameSearch,
    useBHVirtualCarePseudoRollupCodeSearch,
    useOxfordPlnFlag,
    useEnableBhFacilitiesProgramSearchFlag,
    includeAoeOnlySuggestions,
    excludeNatAncForNetworkIds: getExcludeNatAncForNetworkIds(
      excludeNatAncForNetworkIds
    ),
    domain: getDomainForAutoComplete(currentMember),
  };
  // TODO: Move typeAhead Request Data within useAutoCompleteQuery except for the search term
  const [, GetSearchSuggestions] = useAutoCompleteQuery({
    onCompleted: (typeAheadData: any) => {
      const langProvider =
        typeAheadData?.data?.autoComplete?.lang_provider?.langProvider;

      const suggestions =
        typeAheadData?.data?.autoComplete?.lang_provider?.langProvider;
      const combinedRollupCodes =
        typeAheadData?.data?.autoComplete?.lang_provider?.specialityRollUpCodes;
      const aggregatedAoeCodes =
        typeAheadData?.data?.autoComplete?.lang_provider?.aoeCodes;
      const bhProgramFacilityAgg =
        typeAheadData?.data?.autoComplete?.lang_provider?.bhProgramFacilityAgg;
      const providers =
        typeAheadData?.data?.autoComplete?.practitioners_uhc?.provData;
      const facilities =
        typeAheadData?.data?.autoComplete?.organizations_uhc?.orgData;
      let suggestionResults;
      if (hideDisabledTypeahead) {
        suggestionResults = excludeDisabledResults(
          convertedMemberCoverages,
          suggestions
        );
      } else {
        suggestionResults = suggestions;
      }

      const typeAheadSuggestions = setTypeAheadResults(
        suggestionResults,
        providers,
        facilities,
        network
      );

      const isSearchButtonClickOrKeyboardEnter = [
        'search button',
        'search enter',
      ].includes(linkName);
      const isSuggestionClickOrEnter = !isSearchButtonClickOrKeyboardEnter;

      const value = langProvider?.find(
        ({ suggestion }) =>
          suggestion.toLowerCase() === search.toString().toLowerCase()
      );

      if (isSuggestionClickOrEnter) {
        const typeaheadValues = handleSearchBarSuggestionClickOrEnter({
          enableRetriveTypeAheadData,
          specialityPreferenceTable,
          value,
          searchTerm: search,
          commonSearch: false,
          getCoverageType,
          dependentSeqNbr,
          choosePCP,
          chipStore,
          setTypeaheadSearchStore,
          setAnalyticsState,
          setPCPSearchState,
          setChipValue,
          setCoverageType,
          setDetailsStore,
          navigate: undefined,
        });
        onTypeAheadDataLoaded(typeaheadValues);
      } else if (isSearchButtonClickOrKeyboardEnter) {
        const analyticsLinkName = 'search button';
        const typeaheadLinkName = 'typeahead search';

        const typeaheadValues = handleSearchBarSearchButtonClickOrEnter(
          {
            enableRetriveTypeAheadData,
            enableUESSuggestionMatch,
            typeAheadSuggestions: typeAheadSuggestions || [],
            navigate: undefined,
            headers: undefined,
            analyticsLinkName,
            typeaheadLinkName,
            currentMember,
            userSearchTerm: search,
            combinedRollupCodes,
            aggregatedAoeCodes,
            bhProgramFacilityAgg,
            setIsFocusedOnKeywordSearchInput: () => {},
            convertTypeaheadProviderIdAndType,
            getTypeAheadCategory,
            store: {
              chipStore,
              setTypeaheadSearchStore,
              setAnalyticsState,
              setPCPSearchState,
              setChipValue,
              setCoverageType,
              setDetailsStore,
            },
          },
          specialityPreferenceTable
        );
        onTypeAheadDataLoaded(typeaheadValues);
      }
    },
    onError: () => {},
  });

  const newSearch = `${search}-${searchType}-${linkName}-${latitude}-${longitude}`;

  useEffect(() => {
    // If the feature flag is off, retrive the typeahead data from the store.
    if (!enableRetriveTypeAheadData) {
      onTypeAheadDataLoaded(typeaheadSearchStore);
    } else {
      // If the feature flag is on, retrive the typeahead data similar to original flow.
      const isCommonSearch = !!linkName?.includes('common search:');
      const isAutoCompleteSearch = !!(!isCommonSearch && linkName);
      if (isCommonSearch) {
        const value = commonSearchesLagoon.find(
          ({ psxKeyword }) =>
            linkName.split('common search:')?.[1] === psxKeyword
        );
        const typeaheadValues = handleSearchBarSuggestionClickOrEnter({
          enableRetriveTypeAheadData,
          specialityPreferenceTable,
          value,
          searchTerm: search,
          commonSearch: true,
          getCoverageType,
          dependentSeqNbr,
          choosePCP,
          chipStore,
          setTypeaheadSearchStore,
          setAnalyticsState,
          setPCPSearchState,
          setChipValue,
          setCoverageType,
          setDetailsStore,
          navigate: undefined,
        });
        onTypeAheadDataLoaded(typeaheadValues);
      } else if (isAutoCompleteSearch) {
        GetSearchSuggestions({
          variables: typeaheadRequestData,
        });
      } else {
        // fallback to the store if the search is not a common search or an auto complete search
        // TODO: Remaining flows will be implemented in future PRs
        onTypeAheadDataLoaded(typeaheadSearchStore);
      }
    }
  }, [newSearch]);
};
